<template>
  <v-card flat tile :loading="loading">
    <v-list>
      <ReportItem
        v-for="(report, index) in reports"
        v-bind:key="index"
        :report="report"
        :id="schoolClassId"
      >
      </ReportItem>
    </v-list>
  </v-card>
</template>

<script>
import { saveChildRoute } from '@/router/helper';
import ReportItem from '@/components/ReportItem';

export default {
  name: 'PersonReport',
  components: { ReportItem },
  props: {
    id: String,
    person: {},
  },
  computed: {
    schoolClassId() {
      return Number.parseInt(this.id);
    },
  },
  data() {
    return {
      reports: [],
      loading: false,
    };
  },
  watch: {
    person() {
      if (this.person && this.person.type && this.person.type.id != 4) {
        this.$router.push({ name: 'PersonContact' });
        return;
      }
    },
  },
  async created() {
    if (this.person && this.person.type && this.person.type.id != 4) {
      this.$router.push({ name: 'PersonContact' });
      return;
    }
    this.reports = await this.apiList({
      resource: 'app/report',
      query: 'type=student',
    });
  },
  beforeRouteUpdate(to, from, next) {
    saveChildRoute(to, next);
  },
};
</script>

<template>
  <v-list-item @click="generate" :disabled="disabled">
    <v-list-item-icon>
      <v-icon v-if="!generating" :color="color">{{ icon }}</v-icon>
      <v-progress-circular
        v-if="generating"
        :rotate="360"
        :size="30"
        :width="5"
        :value="progress"
      >
      </v-progress-circular>
    </v-list-item-icon>
    <v-list-item-content>{{ report.description }}</v-list-item-content>
    <v-list-item-action v-if="showFavorite" @click.stop="toggleFavorite()"
      ><v-icon v-if="isFavorite">mdi-star</v-icon
      ><v-icon v-else>mdi-star-outline</v-icon></v-list-item-action
    >
  </v-list-item>
</template>
<script>
export default {
  name: 'ReportItem',
  props: {
    id: { type: Number },
    ids: { type: Array },
    report: { type: Object },
    showFavorite: { type: Boolean },
    isFavorite: { type: Boolean },
  },
  data() {
    return {
      progress: 0,
      generating: false,
    };
  },
  computed: {
    disabled() {
      return !this.report.resource || this.generating;
    },
    icon() {
      if (this.generating) {
        return 'mdi-cogs';
      }
      if (this.report.fileType === 'xlsx') {
        return 'mdi-file-excel-box';
      }
      if (this.report.fileType === 'pdf') {
        return 'mdi-file-pdf-box';
      }
      if (this.report.fileType === 'csv') {
        return 'mdi-file-table-box';
      }
      if (this.report.fileType === 'zip') {
        return 'mdi-folder-zip';
      }
      return 'mdi-help-box';
    },
    color() {
      if (this.generating) {
        return '';
      }
      if (this.report.fileType === 'xlsx') {
        return '#1D6F42';
      }
      if (this.report.fileType === 'pdf') {
        return '#F40F02';
      }
      if (this.report.fileType === 'csv') {
        return '#7BBFCC';
      }
      if (this.report.fileType === 'zip') {
        return '#F8D775';
      }
      return '';
    },
  },
  methods: {
    updateProgress(progress) {
      this.progress = progress;
    },
    download(fileInfo) {
      this.generating = false;
      fileInfo.download();
    },
    error() {
      this.$root.showError('Fehler beim erzeugen des Reports!');
      this.generating = false;
    },
    async generate() {
      if (this.generating) return;
      this.progress = 0;
      const parameters = JSON.parse(this.report.parameters);
      if (parameters.inputTitle) {
        parameters.input = prompt(parameters.inputTitle, '0');
        if (!parameters.input) return;
      }
      this.generating = true;
      if (this.id) parameters.id = this.id;
      if (this.ids) parameters.id = this.ids;
      const params = {
        resource: this.report.resource.endpoint,
        data: parameters,
        onProgress: this.updateProgress,
        onDownload: this.download,
        onError: this.error,
      };
      if (!(await this.apiReport(params))) {
        this.generating = false;
      }
    },
    toggleFavorite() {
      this.$emit('toggleFavorite', this.report);
    },
  },
};
</script>
